import anime, { easings } from "animejs";
import axios from "axios";
import Swiper from "swiper/bundle";

//if (document.location.pathname === '/drPetra' || document.location.pathname === '/qrCertification') {
//  window.location.href = 'https://map.naver.com/v5/search/%EC%A0%84%ED%8B%B0%EB%A7%88%EC%9D%B4%20%EC%8C%80%EA%B5%AD%EC%88%98/place/38729426?placePath=%3Fentry%253Dpll&c=15,0,0,0,dh'
//}

var cut1 = anime.timeline({
  easing: "easeOutExpo",
});
cut1.pause();

var cut2 = anime.timeline({
  easing: "easeOutExpo",
});
cut2.pause();
var cut3 = anime.timeline({
  easing: "easeOutExpo",
});
cut3.pause();
var cut4 = anime.timeline({
  easing: "easeOutExpo",
});
cut4.pause();
var cut5 = anime.timeline({
  easing: "easeOutExpo",
});
cut5.pause();

let refreshOptions = ["#1", "#2", "#3", "#4", "#5", "#6"];
let parsedHash = window.location.hash;
var swiper = new Swiper(".swiper-container", {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  },
});
let swiperPaginationElm = document.querySelector(".swiper-pagination");
let swiperWrapper = document.querySelector(".swiper-wrapper");

var cartoonTitle = document.querySelector(".cartoon-title");
function setProperCartoonTitle() {
  let parsedPageIdx = parseInt(window.location.hash.replace("#", "")) - 1;
  if (parsedPageIdx === 5) {
    cartoonTitle.innerHTML = "서비스 가입하기";
    swiperPaginationElm.classList.add("off");
    swiperWrapper.classList.add("expand");
  } else {
    cartoonTitle.innerHTML = "서비스 소개";
    swiperPaginationElm.classList.remove("off");
    swiperWrapper.classList.remove("expand");
  }
}
setProperCartoonTitle();
window.addEventListener("hashchange", function () {
  setProperCartoonTitle();
});

if (!refreshOptions.includes(parsedHash)) {
  anime({
    targets: ".page",
    translateY: {
      value: "-20vh",
      duration: 4000,
    },
    opacity: {
      value: [1, 0],
      duration: 1000,
    },
    delay: anime.stagger(1000, { start: 1000 }),
  });

  anime({
    targets: ".logo-container",
    opacity: 0,
    delay: 3800,
    duration: 100,
    begin: function (anim) {
      cut1.pause();
    },
    complete: function (anim) {
      cut1.restart();
      document.querySelector(".swiper-container").style.zIndex = 101;
      window.location.hash = "1";
    },
  });
} else {
  var pageElms = document.querySelectorAll(".page");
  Array.from(pageElms).forEach((elm) => {
    elm.classList.add("off");
  });
  var logoContainerElm = document.querySelector(".logo-container");
  logoContainerElm.style.display = "none";
  let parsedPageIdx = parseInt(parsedHash.replace("#", "")) - 1;
  swiper.slideTo(parsedPageIdx);
}

swiper.on("slideChange", function (e) {
  if (e.activeIndex === 0) {
    cut1.restart();
    window.location.hash = "1";
  } else if (e.activeIndex === 1) {
    cut2.restart();
    window.location.hash = "2";
  } else if (e.activeIndex === 2) {
    cut3.restart();
    window.location.hash = "3";
  } else if (e.activeIndex === 3) {
    cut4.restart();
    window.location.hash = "4";
  } else if (e.activeIndex === 4) {
    cut5.restart();
    window.location.hash = "5";
  } else {
    window.location.hash = "6";
  }
  if (e.activeIndex === 5) {
    swiperPaginationElm.classList.add("off");
    swiperWrapper.classList.add("expand");
  } else {
    swiperPaginationElm.classList.remove("off");
    swiperWrapper.classList.remove("expand");
  }
});
cut1.add({
  targets: ".cut-1 .people",
  opacity: [0, 1],
  translateY: ["20px", 0],
  duration: 1000,
  delay: 500,
});
cut1.add({
  targets: ".cut-1 .bubble",
  opacity: [0, 1],
  delay: 400,
});

cut2.add({
  targets: ".cut-2 .stage01",
  opacity: [0, 1],
  duration: 500,
  delay: 500,
});
cut2.add({
  targets: ".cut-2 .stage02",
  opacity: {
    value: [0, 1],
    duration: 100,
  },
  translateY: {
    delay: 200,
    duration: 300,
    value: "-55%",
  },
  translateX: {
    delay: 200,
    duration: 300,
    value: "-10%",
  },
});
cut2.add({
  targets: ".cut-2 .stage02",
  translateX: {
    duration: 300,
    value: "60%",
  },
  translateY: {
    value: "-120%",
    duration: 300,
  },
  rotate: {
    value: 60,
    duration: 300,
  },
  scale: {
    value: 0.7,
    duration: 300,
  },
  easing: "spring",
});
cut2.add({
  targets: ".cut-2 .stage03",
  opacity: [0, 1],
  translateY: ["-10%", "-30%"],
  duration: 500,
  delay: 200,
});
cut2.add({
  targets: ".cut-2 .stage04",
  opacity: [0, 1],
  duration: 500,
  delay: 200,
});
cut2.add({
  targets: ".cut-2 .stage05",
  opacity: [0, 1],
  duration: 200,
  delay: 200,
});
cut2.add({
  targets: ".cut-2 .arrow",
  opacity: [0, 1],
  translateY: ["-40%", 0],
  rotate: {
    value: [20, 0],
  },
  duration: 200,
});
cut2.add({
  targets: ".cut-2 .item-seq-2",
  opacity: [0, 1],
  duration: 500,
  easing: "linear",
});
cut2.add(
  {
    targets: ".cut-2 .item-seq-1",
    opacity: 0,
    duration: 200,
    easing: "linear",
  },
  "-=500"
);
cut2.add({
  targets: ".cut-2 .item-seq-3",
  opacity: [0, 1],
  duration: 500,
  easing: "linear",
});
cut2.add(
  {
    targets: ".cut-2 .item-seq-2",
    opacity: 0,
    duration: 200,
    easing: "linear",
  },
  "-=500"
);
cut2.add({
  targets: ".cut-2 .item-seq-4",
  opacity: [0, 1],
  duration: 500,
  easing: "linear",
});
cut2.add(
  {
    targets: ".cut-2 .item-seq-3",
    opacity: 0,
    duration: 200,
    easing: "linear",
  },
  "-=500"
);

// tl.add({
//     targets: '.cuts',
//     translateX: '-200vw',
//     duration: 500,
// })

// cut3.add({
//     targets: '.cut-3 .spoon',
//     opacity: [0, 1],
//     translateX: ['-30%', '-10%'],
//     translateY: ['30%', '10%'],
//     rotate: [-30, -10],
//     duration: 200,
//     delay: 500,
// })
cut3.add({
  targets: ".cut-3 .qrcard",
  opacity: [0, 1],
  translateX: ["30%", 0],
  duration: 700,
  delay: 500,
});
cut3.add({
  targets: ".cut-3 .spoon",
  opacity: [0, 1],
  translateX: ["-30%", 0],
  translateY: ["30%", 0],
  rotate: [-30, 0],
  duration: 500,
});
cut3.add({
  targets: ".cut-3 .flash",
  opacity: [0, 1],
  scale: [0, 1],
  duration: 500,
});
// tl.add({
//     targets: '.cuts',
//     translateX: '-300vw',

// })
cut4.add({
  targets: ".cut-4 .stage01",
  opacity: [0, 1],
  translateY: ["-15%", 0],
  translateY: ["15%", 0],
  duration: 500,
  delay: 500,
});
cut4.add({
  targets: ".cut-4 .flash",
  opacity: [0, 1],
  duration: 500,
});
cut4.add({
  targets: ".cut-4 .arrow",
  opacity: [0, 1],
  duration: 200,
});
cut4.add({
  targets: ".cut-4 .phone1_before",
  opacity: [0, 1],
  duration: 500,
});
cut4.add({
  targets: ".cut-4 .phone1_after",
  opacity: [0, 1],
  duration: 500,
});
// tl.add({
//     targets: '.cuts',
//     translateX: '-400vw',
// })
cut5.add({
  targets: ".cut-5 .em",
  opacity: [0, 1],
  duration: 500,
  delay: 500,
});
cut5.add({
  targets: [".cut-5 .cus", ".cut-5 .bubble"],
  opacity: [0, 1],
  duration: 500,
});
cut5.add({
  targets: [".cut-5 .ppl"],
  opacity: [0, 1],
  duration: 500,
});
cut5.add({
  targets: [".cut-5 .emo"],
  opacity: [0, 1],
  scale: [0, 1],
  duration: 500,
});

var [tab1, tab2] = Array.from(
  document.querySelectorAll(".tabs ul.tabs--list li ")
);
var [tabContent1, tabContent2] = Array.from(
  document.querySelectorAll(".tabs ul.tabs--content li")
);

let currentTab = 0;
let movingTab = document.querySelector(".moving-tab");

tab1.addEventListener("click", (e) => {
  tab1.classList.add("actived");
  tab2.classList.remove("actived");
  movingTab.style.left = `${2.5}%`;
  tabContent1.classList.add("actived");
  tabContent2.classList.remove("actived");
});
tab2.addEventListener("click", (e) => {
  tab1.classList.remove("actived");
  tab2.classList.add("actived");
  movingTab.style.left = `${52.5}%`;
  tabContent1.classList.remove("actived");
  tabContent2.classList.add("actived");
});

let advertiseBtn = document.querySelector("button.advertise");
let partnerBtn = document.querySelector("button.partner");

let containerElm = document.querySelector(".container");
let advertisePage = document.querySelector(".sales.advertise");
let partnerPage = document.querySelector(".sales.partner");

let salesCurrentIdx = 0;
let nextBtn;
let prevBtn;
let btnSpacing;

let stepList;
let salesForm = document.forms[0];
let salesTitle;
let customerType = "advertise";
const BASEURL = process.env.mode === "development" ? "/api" : "";
let step1Validation = [];
let step2Validation = [];
let step3Validation = [];

let termElm = document.querySelector(".terms-page");
let lableContainerElms = document.querySelectorAll(".label-container");
function openTermsAgreePage(clientType) {
  if (clientType !== "advertise") {
    lableContainerElms[1].classList.add("off");
    lableContainerElms[2].classList.add("off");
  }
  containerElm.style.display = "none";
  termElm.classList.add("up");
}

function showTermsAgreePage(clientType) {
  openTermsAgreePage(clientType);
  let termPrevBtn = document.querySelector("button.term-btn.prev-btn");
  let termNextBtn = document.querySelector("button.term-btn.next-btn");
  let checkBoxList = document.querySelectorAll(".control-group input");

  let termDetailBtns = document.querySelectorAll(".chevron_container");
  let termDetailElm = document.querySelector(".term-detail-page");
  let backBtnElm = document.querySelector(".term-detail-page .chevron_btn");
  let termDetailContextElms = document.querySelectorAll(
    ".term-detail-content-text"
  );

  backBtnElm.addEventListener("click", () => {
    termDetailElm.classList.toggle("on");
    Array.from(termDetailContextElms).forEach((elm) =>
      elm.classList.remove("selected")
    );
  });

  Array.from(termDetailBtns).forEach((btn, idx) => {
    btn.addEventListener("click", () => {
      termDetailElm.classList.toggle("on");
      termDetailContextElms[idx].classList.toggle("selected");
    });
  });

  termPrevBtn.addEventListener("click", () => {
    window.location.reload();
  });

  termNextBtn.addEventListener("click", () => {
    if (
      checkBoxList[0].checked &&
      (clientType === "advertise" ? checkBoxList[1].checked : true)
    ) {
      termElm.classList.remove("up");
      if (clientType === "advertise") {
        advertisePage.style.display = "block";
      } else {
        partnerPage.style.display = "block";
      }
    } else {
      alert("입력값을 확인해주세요.");
    }
  });
}

advertiseBtn.addEventListener("click", () => {
  stepList = advertisePage.querySelectorAll(".step");
  customerType = "advertise";

  salesTitle = advertisePage.querySelector(".sales-title");

  nextBtn = advertisePage.querySelector("input.next-btn");
  prevBtn = advertisePage.querySelector("input.prev-btn");
  btnSpacing = advertisePage.querySelector(".spacing-between");
  bindBtnEventListens();
  showTermsAgreePage("advertise");
});
partnerBtn.addEventListener("click", () => {
  stepList = partnerPage.querySelectorAll(".step");
  customerType = "partner";

  salesTitle = partnerPage.querySelector(".sales-title");

  nextBtn = partnerPage.querySelector("input.next-btn");
  prevBtn = partnerPage.querySelector("input.prev-btn");
  btnSpacing = partnerPage.querySelector(".spacing-between");
  bindBtnEventListens();
  showTermsAgreePage("partner");
});

function errorPresent(list, targetForm) {
  list
    .map((key) => ({
      key,
      elm: targetForm[key],
      hasVal: !!targetForm[key].value,
    }))
    .forEach((item) => {
      if (item.hasVal) {
        item.elm.classList.remove("error");
        if (item.elm.parentElement.querySelector(".error-message")) {
          item.elm.parentElement.querySelector(".error-message").remove();
        }
      } else {
        item.elm.classList.add("error");
        let errorMessage = document.createElement("span");
        errorMessage.classList.add("error-message");
        errorMessage.textContent = item.elm.dataset.label;
        item.elm.parentElement.append(errorMessage);
      }
    });
}

let step3ErrorMessageElms = document.querySelectorAll(".step-3 .error-message");
function validateStepForm(targetForm) {
  if (salesCurrentIdx === 0) {
    errorPresent(step1Validation, targetForm);
    return step1Validation
      .map((key) => !!targetForm[key].value)
      .every((v) => v === true);
  } else if (salesCurrentIdx === 1) {
    errorPresent(step2Validation, targetForm);
    return step2Validation
      .map((key) => !!targetForm[key].value)
      .every((v) => v === true);
  } else if (salesCurrentIdx === 2) {
    const checkedTargetChips = Array.from(
      targetForm.querySelectorAll(".target-chip.checked")
    );
    if (checkedTargetChips.length === 0) {
      step3ErrorMessageElms.forEach((elm) => elm.classList.add("on"));
    } else {
      step3ErrorMessageElms.forEach((elm) => elm.classList.remove("on"));
    }
    return checkedTargetChips.length > 0;
  } else {
    return true;
  }
}

function bindBtnEventListens() {
  step1Validation =
    customerType === "advertise"
      ? ["name", "ad-phone", "ad-email"]
      : [
          // "name",
          // "partner-phone",
          "partner-name",
          "partner-address",
          "partner-address-detail",
          "partner-menu",
        ];
  step2Validation =
    customerType === "advertise"
      ? [
          "business-name",
          "business-type",
          "business-address",
          "business-address-detail",
          "business-phone",
        ]
      : [
          "table-number",
          "customer-number",
          "visitor-number",
          "delivery-number",
        ];
  let inputElmList = document.querySelectorAll(".input-container input");
  Array.from(inputElmList).forEach((item) => {
    item.addEventListener("change", (e) => {
      if (e.target.value.length > 0) {
        e.target.classList.remove("error");
        const errorMessage =
          e.target.parentElement.querySelector(".error-message");
        if (errorMessage) {
          errorMessage.remove();
        }
      }
    });
  });
  // step3Validation = customerType === "advertise" ? [] : [];

  nextBtn.addEventListener("click", function (e) {
    if (
      validateStepForm(
        customerType === "advertise" ? document.forms[0] : document.forms[1]
      )
    ) {
      if (salesCurrentIdx < 2) {
        stepList[salesCurrentIdx].classList.remove("visible");
        stepList[salesCurrentIdx].classList.remove("active");
        salesCurrentIdx++;
        stepList[salesCurrentIdx].classList.add("visible");
        setTimeout(() => {
          stepList[salesCurrentIdx].classList.add("active");
        }, 100);
        checkConfirmStep();
      } else if (salesCurrentIdx === 2) {
        let targetForm =
          customerType === "advertise" ? document.forms[0] : document.forms[1];
        let postObj = {};
        const checkedTargetChips = targetForm.querySelectorAll(
          ".target-chip.checked"
        );
        let optionalTerm = document.querySelector(".optional_term_input");

        let POSTURL;
        if (customerType === "advertise") {
          POSTURL = `${BASEURL}/insertPartnerClient`;
          postObj.name = targetForm["name"].value;
          postObj.phone = targetForm["ad-phone"].value;
          postObj.email = targetForm["ad-email"].value;
          postObj.company = targetForm["business-name"].value;
          postObj.companyType = targetForm["business-type"].value;
          postObj.address = targetForm["business-address"].value;
          postObj.detailAddress = targetForm["business-address-detail"].value;
          postObj.tel = targetForm["business-phone"].value;
          postObj.optionalYn = optionalTerm.checked ? "Y" : "N";
        } else {
          POSTURL = `${BASEURL}/insertPartnerRestaurant`;
          // postObj.name = targetForm["name"].value;
          // postObj.phone = targetForm["partner-phone"].value;
          postObj.company = targetForm["partner-name"].value;
          postObj.address = targetForm["partner-address"].value;
          postObj.detailAddress = targetForm["partner-address-detail"].value;
          postObj.menu = targetForm["partner-menu"].value;
          postObj.tableCount = targetForm["table-number"].value;
          postObj.seatsCount = targetForm["customer-number"].value;
          postObj.customerCount = targetForm["visitor-number"].value;
          postObj.deliveryCount = targetForm["delivery-number"].value;
          postObj.optionalYn = optionalTerm.checked ? "Y" : "N";
          postObj.parkingYn = targetForm["parking_lot_T"].checked ? "Y" : "N";
        }
        postObj.targetCustomer = Array.from(checkedTargetChips)
          .map((tag) => tag.innerText.trim())
          .join("|");
        axios
          .post(POSTURL, { ...postObj }, {})
          .then(() => {
            stepList[salesCurrentIdx].classList.remove("visible");
            stepList[salesCurrentIdx].classList.remove("active");
            salesCurrentIdx++;
            stepList[salesCurrentIdx].classList.add("visible");
            setTimeout(() => {
              stepList[salesCurrentIdx].classList.add("active");
            }, 100);
            checkConfirmStep();
          })
          .catch(() => {
            alert("오류발생");
          });
      } else {
        window.location.href = "/";
      }
    } else {
      // alert("입력값을 확인해주세요.");
    }
  });
  prevBtn.addEventListener("click", function (e) {
    if (salesCurrentIdx > 0) {
      stepList[salesCurrentIdx].classList.remove("visible");
      stepList[salesCurrentIdx].classList.remove("active");
      salesCurrentIdx--;
      stepList[salesCurrentIdx].classList.add("visible");
      setTimeout(() => {
        stepList[salesCurrentIdx].classList.add("active");
      }, 100);
    } else {
      advertisePage.style.display = "none";
      partnerPage.style.display = "none";
      openTermsAgreePage(customerType);
    }
    checkConfirmStep();
  });
}

function checkConfirmStep() {
  if (salesCurrentIdx === 2) {
    nextBtn.value = "제출";
  } else if (salesCurrentIdx === 3) {
    prevBtn.style.display = "none";
    btnSpacing.style.display = "none";
    nextBtn.value = "확인";
    salesTitle.value =
      customerType === "advertise" ? "광고 신청 완료 " : "제휴식당 등록 완료";
  } else {
    nextBtn.value = "다음";
  }
}

let advertiseAddress = document.querySelector(
  ".advertise .address-search-input"
);
let partnerAddress = document.querySelector(".partner .address-search-input");
var element_wrap;
var closeBtn;

advertiseAddress.addEventListener("focus", function (e) {
  sample3_execDaumPostcode("ad", e.target);
});
partnerAddress.addEventListener("focus", function (e) {
  sample3_execDaumPostcode("pa", e.target);
});
function foldDaumPostcode() {
  // iframe을 넣은 element를 안보이게 한다.
  element_wrap.style.display = "none";
}

function sample3_execDaumPostcode(type, target) {
  // 현재 scroll 위치를 저장해놓는다.
  var nextFocusElm;
  if (type === "ad") {
    element_wrap = document.getElementById("advertise-wrap");
    closeBtn = document.getElementById("adBtnFoldWrap");
    nextFocusElm = document.querySelector("#business-address-detail");
  } else {
    element_wrap = document.getElementById("partner-wrap");
    closeBtn = document.getElementById("partnerBtnFoldWrap");
    nextFocusElm = document.querySelector("#partner-address-detail");
  }
  closeBtn.addEventListener("click", function () {
    foldDaumPostcode();
  });

  var currentScroll = Math.max(
    document.body.scrollTop,
    document.documentElement.scrollTop
  );
  new daum.Postcode({
    oncomplete: function (data) {
      // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

      // 각 주소의 노출 규칙에 따라 주소를 조합한다.
      // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
      var addr = ""; // 주소 변수
      var extraAddr = ""; // 참고항목 변수

      //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
      if (data.userSelectedType === "R") {
        // 사용자가 도로명 주소를 선택했을 경우
        addr = data.roadAddress;
      } else {
        // 사용자가 지번 주소를 선택했을 경우(J)
        addr = data.jibunAddress;
      }

      // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
      if (data.userSelectedType === "R") {
        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
        if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
          extraAddr += data.bname;
        }
        // 건물명이 있고, 공동주택일 경우 추가한다.
        if (data.buildingName !== "" && data.apartment === "Y") {
          extraAddr +=
            extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
        }
        // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
        if (extraAddr !== "") {
          extraAddr = " (" + extraAddr + ")";
        }
        // 조합된 참고항목을 해당 필드에 넣는다.
        // document.getElementById("sample3_extraAddress").value = extraAddr;
      } else {
        // document.getElementById("sample3_extraAddress").value = '';
      }

      // 우편번호와 주소 정보를 해당 필드에 넣는다.
      // document.getElementById('sample3_postcode').value = data.zonecode;
      // document.getElementById("sample3_address").value = addr;
      // 커서를 상세주소 필드로 이동한다.
      // document.getElementById("sample3_detailAddress").focus();
      target.value = addr;
      target.classList.remove("error");
      let messageElm = target.parentElement.querySelector(".error-message");
      if (messageElm) {
        messageElm.remove();
      }
      nextFocusElm.focus();

      // iframe을 넣은 element를 안보이게 한다.
      // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
      element_wrap.style.display = "none";

      // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
      document.body.scrollTop = currentScroll;
    },
    // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
    onresize: function (size) {
      element_wrap.style.height = size.height + "px";
    },
    width: "100%",
    height: "100%",
  }).embed(element_wrap);

  // iframe을 넣은 element를 보이게 한다.
  element_wrap.style.display = "block";
}

var allChipSelections = document.querySelectorAll(".target-chip");
Array.from(allChipSelections).forEach((chipElm) => {
  chipElm.addEventListener("click", (e) => {
    toggleChipSelection(e.target);
  });
});

function toggleChipSelection(chipElm) {
  chipElm.classList.toggle("checked");
  step3ErrorMessageElms.forEach((elm) => elm.classList.remove("on"));
}
